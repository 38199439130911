import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { COMPANY, MAGNACARE } from 'config/routes'

const hubspotId = process.env.REACT_APP_HUBSPOT_ID;

const loadHubSpotTracking = () => {
  if (document.getElementById("hubspot-script")) return

  const script = document.createElement("script");
  script.id = "hs-script-loader";
  script.src = `https://js.hs-scripts.com/${hubspotId}.js`;
  script.async = true;
  document.body.appendChild(script);
};

const HubSpotTracking = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const trackedPages = [`/${COMPANY}/${MAGNACARE}`];

  useEffect(() => {
    if (!hubspotId) return;

    if (trackedPages.some(path => currentPath?.startsWith(path))) {
      loadHubSpotTracking();
    }
  }, [location]);

  return null;
};

export default HubSpotTracking;

