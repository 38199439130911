import React from 'react';
import Questionnaire from '../Questionnaire';

const ALIGNED_FORMID = process.env.REACT_APP_ALIGNED_FORMID;

const AlignedPage = () => {
  return (
    <Questionnaire formId={ALIGNED_FORMID} />
  )
}

export default AlignedPage